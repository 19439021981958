import React, { Fragment,useState,useEffect } from "react";
import { Form } from "react-bootstrap";
import PageTitle from "../../../layouts/PageTitle";
import axios from "axios";
import swal from "sweetalert";

const Checkout = () => {


    const [loading, setLoading] = useState(false);
    const [depositInput, setDepositInput] = useState({
        current_password: '',
        new_password: '',
        new_confirm_password: '',
        error_list: [],
    });

    const handleInput = (e) => {
        e.persist();
        setDepositInput({...depositInput, [e.target.name]: e.target.value });
    }

    const depositSubmit = (e) => {
        e.preventDefault();
        const auth_token = localStorage.getItem('auth_token');
        const data = {
            current_password: depositInput.current_password,
            new_password: depositInput.new_password,
            new_confirm_password: depositInput.new_confirm_password,
            key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
           
        }
      if (auth_token !== null && !loading) {
          setLoading(true);
          axios.post(`/apps-password-change`, data).then(res => {
          setLoading(false);
                if(res.data.status === 'success')
                {
                   swal("Success",res.data.message,"success");
                  setDepositInput({...depositInput,
                    current_password:'',
                    new_password:'',
                    new_confirm_password:'',
                    
                  });
                }
                else if(res.data.status === 'fail')
                {
                  swal("Warning",res.data.message,"warning");
                }
                else 
                {
                setDepositInput({...depositInput, error_list: res.data.validation_error});
                }
            setLoading(false);
            });
          }

          }



   return (
      <Fragment>
         <div className="row">
            <div className="col-xl-12">
               <div className="card">
                  <div className="card-body">
                     <div className="row">
                        <div className="col-md-12 order-md-1">
                           <h4 className="mb-3">Password Change</h4>
                           <form onSubmit={depositSubmit} className="needs-validation" noValidate="">
                              <div className="row">
                                 <div className="col-md-12 mb-3">
                                    <label htmlFor="lastName">Current password</label>
                                    <input
                                       type="password"
                                       className="form-control"
                                       id="lastName"
                                       placeholder=""
                                       required
                                       name="current_password"
                                       onChange={handleInput} 
                                       value={depositInput.current_password}
                                    />
                                       <span className="text-danger">{depositInput.error_list.current_password}</span>
                                 </div>

                                 <div className="col-md-12 mb-3">
                                    <label htmlFor="firstName">
                                       New Password
                                    </label>
                                    <input
                                       type="password"
                                       className="form-control"
                                       id="firstName"
                                       placeholder=""
                                       required
                                       name="new_password"
                                       onChange={handleInput} 
                                       value={depositInput.new_password}
                                    />
                                       <span className="text-danger">{depositInput.error_list.new_password}</span>
                                    
                                 </div>

                                 <div className="col-md-12 mb-3">
                                    <label htmlFor="firstName">
                                       New Confirm Password
                                    </label>
                                    <input
                                       type="password"
                                       className="form-control"
                                       id="firstName"
                                       placeholder=""
                                       required
                                       name="new_confirm_password"
                                       onChange={handleInput} 
                                       value={depositInput.new_confirm_password}
                                    />
                                   <span className="text-danger">{depositInput.error_list.new_confirm_password}</span>
                                    
                                 </div>
                              </div>

                              <hr className="mb-4" />
                              <button
                                 className="btn btn-primary btn-lg btn-block"
                                 type="submit"
                              >
                              {loading ? 'Changing...' : 'Change Password'}
                              </button>
                           </form>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </Fragment>
   );
};

export default Checkout;
