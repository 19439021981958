const data = {
    "casino": [
        {
            "id": 1,
            "name": "All Game",
            "slug": "/home-game/all-game",
            "photo": "appssource/sports_icons/Bowls.png"
        },
        {
            "id": 2,
            "name": "Slots",
            "slug": "/home-game/slots",
            "photo": "appssource/sports_icons/slots.png"
        },
        {
            "id": 3,
            "name": "Poker",
            "slug": "/home-game/poker",
            "photo": "appssource/sports_icons/poker.png"
        },
        {
            "id": 4,
            "name": "Live Casino",
            "slug": "/home-game/live-casino",
            "photo": "appssource/sports_icons/live_casino.png"
        },
        {
            "id": 5,
            "name": "Jackpot Slots",
            "slug": "/home-game/jackpot-slots",
            "photo": "appssource/sports_icons/Jackpot_slots.png"
        },
        {
            "id": 6,
            "name": "Table Games",
            "slug": "/home-game/table-game",
            "photo": "appssource/sports_icons/Table_Games.png"
        },
        {
            "id": 7,
            "name": "Lottery",
            "slug": "/home-game/lottery",
            "photo": "appssource/sports_icons/lottery.png"
        },
        {
            "id": 8,
            "name": "Crazy Time",
            "slug": "/home-game/crazy-time",
            "photo": "appssource/sports_icons/crazy_time.png"
        },
        {
            "id": 9,
            "name": "Crash",
            "slug": "/home-game/crash",
            "photo": "appssource/sports_icons/crash.png"
        },
        {
            "id": 10,
            "name": "Bingo",
            "slug": "/home-game/bingo",
            "photo": "appssource/sports_icons/bingo.png"
        },
        {
            "id": 11,
            "name": "Fishing",
            "slug": "/home-game/fishing",
            "photo": "appssource/sports_icons/fishing.png"
        },
        {
            "id": 12,
            "name": "Virtual Sports",
            "slug": "/home-game/virtual-sports",
            "photo": "appssource/sports_icons/virtual_sports.png"
        },
        {
            "id": 7,
            "name": "Provider",
            "slug": "/casino-providers",
            "photo": "appssource/sports_icons/provider.png"
        },
        {
            "id": 13,
            "name": "Popular",
            "slug": "/home-game/all-game",
            "photo": "appssource/sports_icons/popular.png"
        }
    ],
   "sports":[
        {
            "id": 1,
            "name": "Soccer",
            "slug": "?bt-path=soccer-1",
            "photo": "appssource/sports_icons/Football.png"
        },
        {
            "id": 2,
            "name": "Tennis",
            "slug": "?bt-path=%2Fsoccer-1",
            "photo": "appssource/sports_icons/Tennis.png"
        },
        {
            "id": 3,
            "name": "Basketball",
            "slug": "?bt-path=%2Fsoccer-1",
            "photo": "appssource/sports_icons/Basketball.png"
        },
        {
            "id": 4,
            "name": "Beach Volleyball",
            "slug": "?bt-path=%2Fsoccer-1",
            "photo": "appssource/sports_icons/Volleyball.png"
        },
        {
            "id": 5,
            "name": "Bowls",
            "slug": "?bt-path=%2Fsoccer-1",
            "photo": "appssource/sports_icons/Bowls.png"
        },
        {
            "id": 6,
            "name": "Cricket",
            "slug": "?bt-path=%2Fsoccer-1",
            "photo": "appssource/sports_icons/stick.png"
        },
        {
            "id": 7,
            "name": "Esports",
            "slug": "?bt-path=%2Fsoccer-1",
            "photo": "appssource/sports_icons/Esports.png"
        },
        {
            "id": 8,
            "name": "Greyhounds",
            "slug": "?bt-path=%2Fsoccer-1",
            "photo": "appssource/sports_icons/Greyhounds.png"
        },
        {
            "id": 9,
            "name": "Horse Racing",
            "slug": "?bt-path=%2Fsoccer-1",
            "photo": "appssource/sports_icons/Horse_Racing.png"
        },
        {
            "id": 10,
            "name": "Squash",
            "slug": "?bt-path=%2Fsoccer-1",
            "photo": "appssource/sports_icons/Basketball.png"
        },
        {
            "id": 11,
            "name": "Table Tennis",
            "slug": "?bt-path=%2Fsoccer-1",
            "photo": "appssource/sports_icons/Table_Tennis.png"
        },
        {
            "id": 12,
            "name": "Volleyball",
            "slug": "?bt-path=%2Fsoccer-1",
            "photo": "appssource/sports_icons/Basketball.png"
        },
        {
            "id": 13,
            "name": " Virtual Sports",
            "slug": "?bt-path=%2Fsoccer-1",
            "photo": "appssource/sports_icons/Virtual_Sports.png"
        },
        {
            "id": 14,
            "name": "Darts",
            "slug": "?bt-path=%2Fcycling-17",
            "photo": "appssource/sports_icons/Darts.png"
        }
    ]
}
export default data;