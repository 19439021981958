/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link ,BrowserRouter as Router,Route} from "react-router-dom";
import { GlobalSet } from '../../components/global/GlobalProvider';
import {useScrollPosition} from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import nav_data from './NavData';
import axios from "axios";


class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() {
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = () =>{
    const var_casino= nav_data.casino;
    const var_sports= nav_data.sports;
    const { BaseUrl } = GlobalSet();

    const {
      iconHover,
      sidebarposition,
      headerposition,
      sidebarLayout,
    } = useContext(ThemeContext);
  useEffect(() => {
    //menu-toggle
      var btn = document.querySelector(".nav-control");
      btn.addEventListener("click", toggleFunc);
      var aaa = document.querySelector("#main-wrapper");
      function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }

  //all-game-tournaments


     //sidebar icon Heart blast
        var handleheartBlast = document.querySelector('.heart');
        function heartBlast() {
            return handleheartBlast.classList.toggle("heart-blast");
        }
        handleheartBlast.addEventListener('click', heartBlast);
  
  }, []);

    // Function to toggle menu
  const toggleMenu = () => {
    const aaa = document.querySelector("#main-wrapper");
    if (aaa) {
      aaa.classList.remove("menu-toggle");
    }
  };

 //For scroll
 const [hideOnScroll, setHideOnScroll] = useState(true)
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y
      if (isShow !== hideOnScroll) setHideOnScroll(isShow)
    },
    [hideOnScroll]
  )
  /// Path
  let path = window.location.pathname;
           console.log(path)

  /// Active menu
  let deshBoard = [
      "",
      "Game",
      "sportsbook",
      ],
    sports = [
     "sports/:id/:matches_slug"
      
    ]

var AuthButtons = '';
if(localStorage.getItem('auth_token'))
{
  AuthButtons = (
  <div className="dropdown header-profile2 ">
    <div className="header-info2 text-center">
      <div>
        <Link to={"/support-ticket"} onClick={toggleMenu} className="btn btn-md text-secondary">Contact Us</Link>
      </div>
    </div>
  </div>
  )
}

  return (
  <div
  style={{ zIndex: 1025 }}
  className={`dlabnav z-3 ${iconHover} ${
  sidebarposition.value === "fixed" &&
  sidebarLayout.value === "horizontal" &&
  headerposition.value === "static"
    ? hideOnScroll > 120
      ? "fixed"
      : ""
    : ""
  }`}
  >
  <PerfectScrollbar className="dlabnav-scroll">
  <MM className="metismenu" id="menu">

{/*      <li className={`${deshBoard.includes(path) ? "mm-active" : ""}`}>
          <Link className="has-arrow" to="#" >
            <i className="fas fa-home"></i>
            <span className="nav-text">Home</span>
          </Link>
          <ul>
            <li>
               <Link className={`${path === "sportsbook" ? "mm-active" : ""}`} to="/sportsbook" onClick={toggleMenu}>Lottery
               </Link>
            </li>
            <li>
               <Link className={`${path === "all-game" ? "mm-active" : ""}`} to="/all-game" onClick={toggleMenu}>Casino
               </Link>
            </li>            
          </ul>
      </li>*/}

    <div className="header-info2 nav-text text-center p-1">
      <div role="group" class="btn-group  rounded-pill" style={{border: "2px solid rgb(207 207 207)"}} >
        <Link onClick={toggleMenu} class={"fas fa-cubes rounded-pill " + (path != "/sportsbook" ? "bg-secondary" : "")} to="/"> Casino</Link>
        <Link onClick={toggleMenu} class={"fas fa-dice rounded-pill " + (path != "/sportsbook" ? "" : "bg-secondary")} to="/sportsbook"> Sports</Link>
      </div>
    </div>

    {path !== "/sportsbook" ? (
      var_casino.map((product) => (
        <li>     
           <Link className="has-arrow" key={product.id} to={product.slug} onClick={toggleMenu}>   
            <img src={BaseUrl + 'public/' + product.photo} alt="" width="20" />
            <span className="nav-text text-black"> {product.name}</span>
          </Link>
        </li>
      ))      
    ) : (
      var_sports.map((product) => (
        <li>
          <Link className="has-arrow"  key={product.id} to={product.slug} onClick={toggleMenu}>
            <img src={BaseUrl + 'public/' + product.photo} alt="" width="20" />
            <span className="nav-text text-black"> {product.name}</span>
          </Link>
        </li>
      ))
    )}
  </MM>
  {AuthButtons}
  <div className="copyright">
    <p className="text-center"><strong>Planbet24</strong> © 2023 All Rights Reserved</p>
    <p className="fs-12 text-center">Made with <span className="heart"></span> by Planbet24</p>
  </div>
  </PerfectScrollbar>
  </div>
  );
};

export default SideBar;
