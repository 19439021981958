import React, { Fragment,useState,useEffect } from "react";
import { Form,  Card,  Badge,} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import swal from "sweetalert";
//lg
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';

const Checkout = () => {

  const history = useHistory();
  const [selectedOption, setSelectedOption] = useState(null);
   const { t } = useTranslation();
   const [options, setoptions] = useState([]);

    useEffect(()=>{
        const data = {
              key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
             }
        axios.post('/new-ticket',data)
        .then(response=>{
           setoptions(response.data.data)
           
       })
     .catch(error=>{
            
           console.log(error)
       })
     },[])


    const [depositInput, setDepositInput] = useState({
        subject: '',
        message: '',
        w_select: '',
        error_list: [],
    });

    const handleInput = (e) => {
        e.persist();
        setDepositInput({...depositInput, [e.target.name]: e.target.value });
    }

    const depositSubmit = (e) => {
        e.preventDefault();
        const auth_token = localStorage.getItem('auth_token');
        const data = {
            subject: depositInput.subject,
            message: depositInput.message,
            w_select: depositInput.w_select,
            key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
           
        }

      if (auth_token !== null) {
          axios.post(`/new-ticket-post`, data).then(res => {
                if(res.data.status === 'success')
                {
                   swal("Success",res.data.message,"success");
                    history.push('/support-ticket');
                  setDepositInput({...depositInput,
                    subject: '',
                    message: '',
                    w_select: '',
                    
                  });
                }
                else if(res.data.status === 'fail')
                {
                  swal("Warning",res.data.message,"warning");
                }
                else 
                {
                setDepositInput({...depositInput, error_list: res.data.validation_error});
                }
            });
          }
         }
   return (
      <Fragment>
         <div className="row">
            <div className="col-xl-12">
               <div className="card">
                  <div className="card-body">
                     <div className="row">
                        <div className="col-md-12">
                          <Card.Header>
                            <Card.Title>Support Ticket</Card.Title>
                            <Card.Title><span> 
                              <Link to="/support-ticket"><Badge variant="success light">All Ticket</Badge></Link></span></Card.Title>
                           </Card.Header>
                          <div className="card-body">
                           <form onSubmit={depositSubmit} className="needs-validation" noValidate="">
                              <div className="row">

                                 <div className="col-md-12 mb-3">
                                    <label>Ticket Type</label>
                                    <select name="w_select" 
                                       onChange={handleInput} 
                                       required
                                       value={depositInput.w_select}
                                       className="form-control">
                                           <option value="">{t('pro_with_sel_get')}</option>
                                           {options.map((ele)=>(
                                           <option value={ele.value} key={ele.value}>{ele.label}</option>
                                           ))}
                                    </select>
                                    <span className="text-danger">{depositInput.error_list.w_select}</span>
                                 </div>


                                 <div className="col-md-12 mb-3">
                                    <label htmlFor="lastName">Subject</label>
                                    <input
                                       type="text"
                                       className="form-control"
                                       id="lastName"
                                       placeholder="Subject"
                                       required
                                       name="subject"
                                       onChange={handleInput} 
                                       value={depositInput.subject}
                                    />
                                       <span className="text-danger">{depositInput.error_list.subject}</span>
                                 </div>



                                 <div className="col-md-12 mb-3">
                                     <label htmlFor="lastName">Message</label>
                                      <div className="form-group mb-3">
                                      <textarea onChange={handleInput}  required name="message" id="email-compose-editor" className="textarea_editor form-control bg-transparent" rows="8" placeholder="Enter text ..." spellcheck="false">
                                      {depositInput.message}
                                      </textarea>
                                      </div>
                                      <span className="text-danger">{depositInput.error_list.message}</span>
                                 </div>


                              </div>

                              <hr className="mb-4" />
                              <button
                                 className="btn btn-primary btn-lg btn-block"
                                 type="submit"
                              >
                                 Submit 
                              </button>
                           </form>
                          </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </Fragment>
   );
};

export default Checkout;
